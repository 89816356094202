@import "../variables/colors";


.tmpt{
    &-hero{
        &-bg{
            background-size: cover;
            background-position: center center;
            position: relative;
            &-filter{
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }
    }
    &-btn{
        .light{
            color: white;
        }
        &:hover{
            filter: brightness(0.85)
        }
    }
    &-editor{
        li{
            margin-bottom: .5rem;
        }
    }
    &-zoom-calendar{
        .border{
            border-color: $pr-gray-4 !important;
        }
        .btn-rounded-success{
            position: relative;
            .hour, .day{
                flex-direction: column;
                justify-content: center;
                position: absolute;
                width: 100%;
                height: 100%;
                transition: opacity .3s;
            }
            .hour{
                opacity: 0;
                display: none;
                z-index: 2;
                background-color: $pr-primary;
                left: -1000;
                top: -1000;
                a{
                    color: white;
                }
            }
            .day{
                z-index: 3;
                background-color: #005cbf20;
                color: $pr-primary;
                left: 0;
                top: 0;
            }
            &:hover, &.hover{
                .hour{
                    display: flex;
                    opacity: 1;
                    z-index: 4;
                    left: 0;
                    top: 0;
                }
            }
            .current-date{
                color: $pr-secondary;
                font-weight: bold;
            }
        }
        .occurrence{
            font-size: 60%;
            @media screen and (min-width: 7680px) {
                font-size: 80%;
            }
        }
    }
}

.title_home{
    >div{
        background-color: rgba($pr-primary,.5);
    }
}

.group-card{
    .padding-gray{
        background-color: $pr-gray-4;
    }
}

.title-card{
    background-color: $pr-gray-4;
}

.modal-backdrop {
    background-color: transparent;
  }
  .modal-backdrop-transparent {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: -1000;
  }
  .modal-backdrop-show {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $pr-gray-1;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    z-index: 1040;
  }
  .modal-transition {
    transition: all 0.4s ease;
  }
  .modal-backdrop{
      display: none;
  }